var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","background":"#f3f7f9","overflow-y":"auto"}},[_c('div',{staticClass:"yt-breadcrumb-wrapper"},[_c('el-breadcrumb',{staticClass:"yt-breadcrumb"},[_c('el-breadcrumb-item',[_vm._v("数据分析")]),_c('el-breadcrumb-item',{attrs:{"to":{ name: 'staffDetail', params: { userId: _vm.userId } }}},[_vm._v("用户评估")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.type === 'course' ? '课程统计' : '考试统计'))])],1)],1),_c('div',{staticStyle:{"padding":"10px"}},[_c('div',{staticClass:"top"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{class:_vm.type === 'course' ? 'titleAct' : 'title',on:{"click":function($event){return _vm.changeType('course')}}},[_vm._v(" 课程统计 ")]),_c('div',{class:_vm.type === 'course' ? 'title' : 'titleAct',staticStyle:{"margin-left":"100px"},on:{"click":function($event){return _vm.changeType('exam')}}},[_vm._v(" 考试统计 ")])]),_c('Input',{staticClass:"search-bar",attrs:{"search":"","placeholder":"请输入关键字"},on:{"on-search":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('Table',{attrs:{"height":_vm.th,"data":_vm.type === 'course' ? _vm.courseData : _vm.examData,"columns":_vm.type === 'course' ? _vm.courseColumn : _vm.examColumn,"loading":_vm.type === 'course' ? _vm.loading : _vm.examLoading},scopedSlots:_vm._u([{key:"userCourseProgress",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',{style:({
            color: row.userCourseProgress * 100 >= 60 ? '#5ABC50' : '#E66B42'
          })},[_vm._v(_vm._s(Math.round(row.userCourseProgress * 100))+"%")])]}},{key:"startStudyTime",fn:function(ref){
          var row = ref.row;
          var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startStudyTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"startTime",fn:function(ref){
          var row = ref.row;
          var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"difficult",fn:function(ref){
          var row = ref.row;
          var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.paperLevel === undefined ? '' : _vm.examColumn[6].filters[row.paperLevel].label))])]}}])}),_c('Page',{staticClass:"yt-page",class:'yt-page-' +
          (_vm.type === 'course'
            ? _vm.total.toString().length > 1
              ? _vm.total.toString().length
              : 2
            : _vm.examTotal.toString().length > 1
            ? _vm.examTotal.toString().length
            : 2),attrs:{"current":_vm.type === 'course' ? _vm.page + 1 : _vm.examPage + 1,"page-size":_vm.type === 'course' ? _vm.size : _vm.examSize,"total":_vm.type === 'course' ? _vm.total : _vm.examTotal,"show-sizer":"","show-elevator":"","show-total":""},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changeSize}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }