<template>
  <div style="height: 100%; background: #f3f7f9; overflow-y: auto;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'staffDetail', params: { userId: userId } }">用户评估</el-breadcrumb-item>
        <el-breadcrumb-item>{{ type === 'course' ? '课程统计' : '考试统计' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px;">
      <div class="top">
        <div style="display: flex">
          <div @click="changeType('course')" :class="type === 'course' ? 'titleAct' : 'title'">
            课程统计
          </div>
          <div @click="changeType('exam')" :class="type === 'course' ? 'title' : 'titleAct'" style="margin-left: 100px">
            考试统计
          </div>
        </div>
        <Input class="search-bar" search placeholder="请输入关键字" @on-search="search" v-model="keyword" />
      </div>
      <Table
        :height="th"
        :data="type === 'course' ? courseData : examData"
        :columns="type === 'course' ? courseColumn : examColumn"
        :loading="type === 'course' ? loading : examLoading"
      >
        <template slot-scope="{ row, index }" slot="userCourseProgress">
          <span
            :style="{
              color: row.userCourseProgress * 100 >= 60 ? '#5ABC50' : '#E66B42'
            }"
            >{{ Math.round(row.userCourseProgress * 100) }}%</span
          >
        </template>
        <template slot-scope="{ row, index }" slot="startStudyTime">
          <span>{{ $formatTime(row.startStudyTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="startTime">
          <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="difficult">
          <span>{{ row.paperLevel === undefined ? '' : examColumn[6].filters[row.paperLevel].label }}</span>
        </template>
      </Table>
      <Page
        class="yt-page"
        :class="
          'yt-page-' +
            (type === 'course'
              ? total.toString().length > 1
                ? total.toString().length
                : 2
              : examTotal.toString().length > 1
              ? examTotal.toString().length
              : 2)
        "
        :current="type === 'course' ? page + 1 : examPage + 1"
        :page-size="type === 'course' ? size : examSize"
        @on-change="changePage"
        @on-page-size-change="changeSize"
        :total="type === 'course' ? total : examTotal"
        show-sizer
        show-elevator
        show-total
      ></Page>
    </div>
  </div>
</template>

<script>
import ExpandRow from '@components/manage/analysis/ExpandRow'
import questionRepo from '@components/common/old/questionOld'

export default {
  name: 'AssessmentStatistics',
  components: { ExpandRow },
  data() {
    return {
      courseColumn: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(ExpandRow, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          title: '课程名称',
          key: 'courseName'
        },
        {
          title: '课程描述',
          key: 'description',
          tooltip: true
        },
        {
          title: '最新学习章节',
          key: 'chapterSequence',
          width: 150
        },
        {
          title: '学习进度',
          slot: 'userCourseProgress',
          width: 150
        },
        {
          title: '开始学习时间',
          slot: 'startStudyTime',
          width: 150
        }
      ],
      courseData: [],
      page: 0,
      size: 10,
      examPage: 0,
      examSize: 10,
      th: document.body.clientHeight - 185,
      total: 0,
      examTotal: 0,
      loading: false,
      examLoading: false,
      examColumn: [
        {
          title: '考试名称',
          key: 'examName'
        },
        {
          title: '试卷名称',
          key: 'paperName'
        },
        {
          title: '试卷库名称',
          key: 'paperBankName'
        },
        {
          title: '用时(分钟)',
          key: 'duration',
          width: 100
        },
        {
          title: '题数',
          key: 'questionAmount',
          width: 100
        },
        {
          title: '分数',
          key: 'score',
          width: 100
        },
        {
          title: '难度',
          slot: 'difficult',
          width: 100,
          filters: [
            {
              label: '简单',
              value: 0
            },
            {
              label: '一般',
              value: 1
            },
            {
              label: '困难',
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            return row.paperLevel === value
          }
        },
        {
          title: '考试时间',
          slot: 'startTime',
          width: 140
        },
        {
          title: '总分',
          key: 'total',
          sortable: true,
          width: 100
        }
      ],
      examData: [],
      keyword: '',
      type: '',
      userId: ''
    }
  },
  created() {
    let params = this.$handleParams('staffStatisticsData')
    this.type = params.type
    this.userId = params.id
  },
  mounted() {
    if (this.type === 'course') {
      this.initCourse()
    } else {
      this.initExam()
    }
  },
  methods: {
    initExam() {
      this.examLoading = true
      questionRepo
        .getExamList(this.userId, this.examPage, this.examSize, this.keyword)
        .then(res => {
          this.examTotal = res.res.total
          this.examData = res.res.data
          this.examLoading = false
        })
        .catch(() => {
          this.examLoading = false
        })
    },
    initCourse() {
      this.loading = true
      questionRepo
        .getCourseList(this.userId, this.page, this.size, this.keyword)
        .then(res => {
          this.total = res.res.total
          this.courseData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      if (this.type === 'course') {
        this.page = val - 1
        this.initCourse()
      } else {
        this.examPage = val - 1
        this.initExam()
      }
    },
    changeSize(val) {
      if (this.type === 'course') {
        this.size = val
        this.initCourse()
      } else {
        this.examSize = val
        this.initExam()
      }
    },
    search() {
      if (this.type === 'course') {
        this.page = 0
        this.size = 10
        this.initCourse()
      } else {
        this.examPage = 0
        this.examSize = 10
        this.initExam()
      }
    },
    changeType(val) {
      this.keyword = ''
      this.type = val
      if (this.type === 'course') {
        this.initCourse()
      } else {
        this.initExam()
      }
    }
  }
}
</script>

<style scoped lang="less">
.top {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 5px rgba(7, 27, 41, 0.1);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
  .search-bar {
    width: 300px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .titleAct {
    padding-top: 3px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(86, 143, 235, 1);
    cursor: pointer;
    height: 50px;
    border-bottom: 3px solid #568feb;
  }
}
</style>
